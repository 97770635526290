var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.page === 'login')?[_c('validation-observer',{ref:"loginForm"},[_c('b-form',{staticClass:"auth-login-form",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{staticClass:"input-contain",attrs:{"label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-color",attrs:{"id":"Email","name":"login-user","state":errors.length > 0 ? false : null,"placeholder":"ชื่อผู้ใช้","autofocus":""},model:{value:(_vm.inputs.username),callback:function ($$v) {_vm.$set(_vm.inputs, "username", $$v)},expression:"inputs.username"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกชื่อผู้ใช้")]):_vm._e()]}}],null,false,2112473257)})],1),_c('b-form-group',{attrs:{"label-for":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-color",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"login-password","placeholder":"รหัสผ่าน"},on:{"keyup":_vm.onEnterPress},model:{value:(_vm.inputs.pass),callback:function ($$v) {_vm.$set(_vm.inputs, "pass", $$v)},expression:"inputs.pass"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกรหัสผ่าน")]):_vm._e()]}}],null,false,2188209434)})],1)],1)],1),_c('div',{staticClass:"row justify-content-between p-1"},[_c('a',{on:{"click":function($event){return _vm.onClickPage('register')}}},[_vm._m(0)]),_vm._m(1)]),_c('b-button',{staticClass:"mb-2 w-100",attrs:{"variant":"danger","type":"submit","block":""},on:{"click":_vm.loginJWT}},[_vm._v(" เข้าสู่ระบบ ")])]:_vm._e(),(_vm.page === 'register')?[_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-login-form",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-color",attrs:{"id":"Email","name":"login-user","state":errors.length > 0 ? false : null,"placeholder":"ชื่อผู้ใช้*","autofocus":""},model:{value:(_vm.inputsRegister.email),callback:function ($$v) {_vm.$set(_vm.inputsRegister, "email", $$v)},expression:"inputsRegister.email"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกชื่อผู้ใช้")]):_vm._e()]}}],null,false,383406096)})],1),_c('b-form-group',{attrs:{"label-for":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-color",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"login-password","placeholder":"รหัสผ่าน*"},model:{value:(_vm.inputsRegister.password),callback:function ($$v) {_vm.$set(_vm.inputsRegister, "password", $$v)},expression:"inputsRegister.password"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกรหัสผ่าน")]):_vm._e()]}}],null,false,3891185248)})],1),_c('b-form-group',{attrs:{"label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-color",attrs:{"id":"Email","name":"login-user","state":errors.length > 0 ? false : null,"placeholder":"ชื่อจริง*"},model:{value:(_vm.inputsRegister.firstName),callback:function ($$v) {_vm.$set(_vm.inputsRegister, "firstName", $$v)},expression:"inputsRegister.firstName"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกชื่อจริง")]):_vm._e()]}}],null,false,2065128980)})],1),_c('b-form-group',{attrs:{"label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-color",attrs:{"id":"lastName","name":"login-user","state":errors.length > 0 ? false : null,"placeholder":"นามสกุล*"},model:{value:(_vm.inputsRegister.lastName),callback:function ($$v) {_vm.$set(_vm.inputsRegister, "lastName", $$v)},expression:"inputsRegister.lastName"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกนามสกุล")]):_vm._e()]}}],null,false,2518889541)})],1),_c('validation-provider',{attrs:{"name":"bankName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"h-first-name"}},[_c('b-form-select',{staticClass:"input-color",attrs:{"options":_vm.optionBank,"state":errors.length > 0 ? false : null},model:{value:(_vm.inputsRegister.bankaccountName),callback:function ($$v) {_vm.$set(_vm.inputsRegister, "bankaccountName", $$v)},expression:"inputsRegister.bankaccountName"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("โปรดระบุบัญชีธนาคาร")]):_vm._e()],1)]}}],null,false,3584321103)}),_c('b-form-group',{attrs:{"label-for":"telephoneNumber"}},[_c('validation-provider',{attrs:{"name":"User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-color",attrs:{"id":"telephoneNumber","type":"tel","name":"login-user","state":errors.length > 0 ? false : null,"placeholder":"เลขบัญชีธนาคาร*"},model:{value:(_vm.inputsRegister.bankaccountNumber),callback:function ($$v) {_vm.$set(_vm.inputsRegister, "bankaccountNumber", $$v)},expression:"inputsRegister.bankaccountNumber"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกเลขบัญชีธนาคาร")]):_vm._e()]}}],null,false,1163875893)})],1),_c('b-form-group',{attrs:{"label-for":"line"}},[_c('validation-provider',{attrs:{"name":"User","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-color",attrs:{"id":"line","name":"line","state":errors.length > 0 ? false : null,"placeholder":"ไลน์*"},model:{value:(_vm.inputsRegister.line),callback:function ($$v) {_vm.$set(_vm.inputsRegister, "line", $$v)},expression:"inputsRegister.line"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("กรุณากรอกไลน์")]):_vm._e()]}}],null,false,2246833556)})],1),_c('b-button',{staticClass:"mb-2 mt-1 w-100",attrs:{"variant":"primary","type":"submit","block":""},on:{"click":_vm.addCustomer}},[_vm._v(" ยืนยัน ")]),_c('b-button',{staticClass:"mb-2 w-100",attrs:{"variant":"danger","type":"submit","block":""},on:{"click":function () { return (_vm.page = 'login'); }}},[_vm._v(" ย้อนกลับ ")])],1)],1)]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('u',[_vm._v("ลงทะเบียน")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',[_c('p',[_c('u',[_vm._v("ลืมรหัสผ่าน?")])])])}]

export { render, staticRenderFns }