<template>
  <div>
    <!-- <div class="d-flex justify-content-center">
      <b-img
        class="mb-2"
        style="width:10rem"
        :src="require('@/assets/images/production/logo.png')"
      />
    </div> -->
    <template v-if="page === 'login'">
      <validation-observer ref="loginForm">
        <b-form class="auth-login-form" @submit.prevent>
          <!-- email -->
          <b-form-group label-for="Email" class="input-contain">
            <validation-provider
              #default="{ errors }"
              name="User"
              rules="required"
            >
              <b-form-input
                id="Email"
                v-model="inputs.username"
                name="login-user"
                :state="errors.length > 0 ? false : null"
                placeholder="ชื่อผู้ใช้"
                autofocus
                class="input-color"
              />
              <small v-if="errors.length > 0" class="text-danger"
                >กรุณากรอกชื่อผู้ใช้</small
              >
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="Password">
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-form-input
                id="password"
                v-model="inputs.pass"
                :type="passwordFieldType"
                class="input-color"
                :state="errors.length > 0 ? false : null"
                name="login-password"
                placeholder="รหัสผ่าน"
                v-on:keyup="onEnterPress"
              />
              <small v-if="errors.length > 0" class="text-danger"
                >กรุณากรอกรหัสผ่าน</small
              >
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <div class="row justify-content-between p-1">
        <a @click="onClickPage('register')"
          ><p><u>ลงทะเบียน</u></p></a
        >
        <a
          ><p><u>ลืมรหัสผ่าน?</u></p></a
        >
      </div>
      <b-button
        variant="danger"
        type="submit"
        block
        class="mb-2 w-100"
        @click="loginJWT"
      >
        เข้าสู่ระบบ
      </b-button>
    </template>
    <template v-if="page === 'register'">
      <validation-observer ref="registerForm">
        <b-form class="auth-login-form" @submit.prevent>
          <!-- email -->
          <b-form-group label-for="Email">
            <validation-provider
              #default="{ errors }"
              name="User"
              rules="required"
            >
              <b-form-input
                id="Email"
                v-model="inputsRegister.email"
                name="login-user"
                :state="errors.length > 0 ? false : null"
                placeholder="ชื่อผู้ใช้*"
                class="input-color"
                autofocus
              />
              <small v-if="errors.length > 0" class="text-danger"
                >กรุณากรอกชื่อผู้ใช้</small
              >
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="Password">
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-form-input
                id="password"
                v-model="inputsRegister.password"
                :type="passwordFieldType"
                class="input-color"
                :state="errors.length > 0 ? false : null"
                name="login-password"
                placeholder="รหัสผ่าน*"
              />
              <small v-if="errors.length > 0" class="text-danger"
                >กรุณากรอกรหัสผ่าน</small
              >
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="Email">
            <validation-provider
              #default="{ errors }"
              name="User"
              rules="required"
            >
              <b-form-input
                id="Email"
                v-model="inputsRegister.firstName"
                name="login-user"
                :state="errors.length > 0 ? false : null"
                placeholder="ชื่อจริง*"
                class="input-color"
              />
              <small v-if="errors.length > 0" class="text-danger"
                >กรุณากรอกชื่อจริง</small
              >
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="Email">
            <validation-provider
              #default="{ errors }"
              name="User"
              rules="required"
            >
              <b-form-input
                id="lastName"
                v-model="inputsRegister.lastName"
                name="login-user"
                :state="errors.length > 0 ? false : null"
                placeholder="นามสกุล*"
                class="input-color"
              />
              <small v-if="errors.length > 0" class="text-danger"
                >กรุณากรอกนามสกุล</small
              >
            </validation-provider>
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="bankName"
            rules="required"
          >
            <b-form-group label-for="h-first-name">
              <b-form-select
                v-model="inputsRegister.bankaccountName"
                :options="optionBank"
                :state="errors.length > 0 ? false : null"
                class="input-color"
              />
              <small v-if="errors.length > 0" class="text-danger"
                >โปรดระบุบัญชีธนาคาร</small
              >
            </b-form-group>
          </validation-provider>
          <b-form-group label-for="telephoneNumber">
            <validation-provider
              #default="{ errors }"
              name="User"
              rules="required"
            >
              <b-form-input
                id="telephoneNumber"
                v-model="inputsRegister.bankaccountNumber"
                type="tel"
                name="login-user"
                class="input-color"
                :state="errors.length > 0 ? false : null"
                placeholder="เลขบัญชีธนาคาร*"
              />
              <small v-if="errors.length > 0" class="text-danger"
                >กรุณากรอกเลขบัญชีธนาคาร</small
              >
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="line">
            <validation-provider
              #default="{ errors }"
              name="User"
              rules="required"
            >
              <b-form-input
                id="line"
                v-model="inputsRegister.line"
                name="line"
                class="input-color"
                :state="errors.length > 0 ? false : null"
                placeholder="ไลน์*"
              />
              <small v-if="errors.length > 0" class="text-danger"
                >กรุณากรอกไลน์</small
              >
            </validation-provider>
          </b-form-group>
          <b-button
            variant="primary"
            type="submit"
            block
            class="mb-2 mt-1 w-100"
            @click="addCustomer"
          >
            ยืนยัน
          </b-button>
          <b-button
            variant="danger"
            type="submit"
            block
            class="mb-2 w-100"
            @click="() => (page = 'login')"
          >
            ย้อนกลับ
          </b-button>
        </b-form>
      </validation-observer>
    </template>
  </div>
</template>

<script>
import {
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BFormGroup,
  BImg,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import useJwt from '@/auth/jwt/useJwt'

export default {
  name: "LoginFrom",
  components: {
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormGroup,
    BImg,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputs: {
        username: "",
        pass: "",
      },
      inputsRegister: {
        username: "",
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        type: "U",
        telephoneNumber: "",
        bankaccountNumber: "",
        bankaccountName: null,
        employerId: null,
        countryId: null,
        line: "",
      },
      page: this.$store.state.customer.typeModal,
      required,
      email,
      optionBank: [
        {
          value: null,
          text: "เลือกธนาคาร",
        },
        {
          value: "SCB",
          text: "ธนาคารไทยพาณิชย์",
        },
        {
          value: "KBANK",
          text: "ธนาคารกสิกร",
        },
        {
          value: "KTB",
          text: "ธนาคารกรุงไทย",
        },
        {
          value: "GSB",
          text: "ธนาคารออมสิน",
        },
        {
          value: "TMB",
          text: "ธนาคารทหารไทย",
        },
        {
          value: "BBL",
          text: "ธนาคารกรุงเทพ",
        },
      ],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  watch: {
    "inputsRegister.email": {
      handler(val) {
        this.inputsRegister.username = val;
      },
    },
  },
  methods: {
    onEnterPress(e) {
      if (e.keyCode === 13) {
        this.loginJWT();
      }
    },
    onClickPage(val) {
      this.page = val;
    },
    loadInputsDefult() {
      this.inputsRegister = {
        username: "",
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        bankaccountNumber: "",
        bankaccountName: null,
        line: "",
      };
      this.inputs = {
        email: "",
        pass: "",
      };
    },
    loginJWT() {
      const payload = {
        username: this.inputs.username,
        password: this.inputs.pass,
      };
      this.$store
        .dispatch("auth/loginJWT", payload)
        .then((result) => {
          if (result.data.success) {
            console.log(result.data);
            const userData = result.data.item;
            // useJwt.setToken(result.data.accessToken)
            // useJwt.setRefreshToken(result.data.refreshToken)
            const newToken =
              result.data.accesstoken + ":" + result.data.refreshtoken;
            userData.ability = [
              {
                action: "manage",
                subject: "all",
              },
            ];
            const item = {
              token: newToken,
              auth: true,
            };
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("auth_token", newToken);
            localStorage.setItem("auth_user", item.auth);
            this.$store.commit("auth/SET_LOGIN_COMPLETE", item);
            // this.$store.commit("auth/setUserData", userData);
            this.$ability.update(userData.ability);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `ยินดีตอนรับ ${userData.fullName || userData.username}`,
                icon: "AlertCircleIcon",
                variant: "success",
                text: "คุณล็อกอินสำเร็จ",
              },
            });
            this.$emit("loginsuccess");
            // if (this.type === "pages") {
            //   this.$emit("back");
            // }
            this.$store.commit("auth/SET_SPINNER_CREDIT", true);
            this.$store
              .dispatch("transaction/getCredit")
              .then((result) => {
                if (result.data.success) {
                  this.$store.commit("auth/SET_SPINNER_CREDIT", false);
                  this.$store.commit("auth/SET_CREDIT", result.data.credit);
                } else {
                  this.$store.commit("auth/SET_SPINNER_CREDIT", false);
                  this.$store.commit("auth/SET_CREDIT", 0);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "ล็อกอินไม่สำเร็จ",
                icon: "AlertCircleIcon",
                variant: "danger",
                text: "ชื่อผู้ใช้หรือรหัสผ่านผิดพลาด กรุณาลองใหม่อีกครั้ง",
              },
            });
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "ล็อกอินไม่สำเร็จ",
              icon: "AlertCircleIcon",
              variant: "danger",
              text: error.message,
            },
          });
        });
    },
    addCustomer() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch("auth/Register", this.inputsRegister)
            .then((response) => {
              console.log(response);
              if (response.data.success) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "ลูกค้า",
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: response.data.message,
                  },
                });
                this.onClickPage("login");
                this.loadInputsDefult();

                this.$refs.registerForm.reset();
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "ลูกค้า",
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: response.data.message,
                  },
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "ลูกค้า",
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: error.data.message,
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.buttonFB {
  background-color: #3c5998 !important;
  color: white !important;
}

.input-color {
  background-color: #1b1a2f !important;
  color: white !important;
  border-color: #263e72 !important;
}
</style>
