<template>
  <!-- <div class="bg-black" :class="contentWidth === 'boxed' ? 'container p-0' : 'container-fuild'"> -->
  <div class="bg-black">
    <b-overlay :show="$store.state.game.isLoading" rounded="lg">
      <Login ref="loginModal" />
      <div class="sticky-top bg-dark">
        <b-navbar toggleable="lg" type="dark" variant="black" id="nav-top">
          <b-link href="/">
            <div class="row">
              <!-- <b-img
          :src="require('@/assets/images/production/facebook.png')"
          style="width:35px;margin-right:3px;cursor: pointer;"
          class="ml-xl-3"
        />
        <b-img
          :src="require('@/assets/images/production/Instagram-icon-removeb.png')"
          style="width:45px;cursor: pointer;"
        />
        <b-navbar-brand
          tag="h1"
          class="mb-0 ml-1 mr-1"
          style="   font-size: 1.4rem;"
        >
          @HdayThailand
        </b-navbar-brand>
        <b-img
          :src="require('@/assets/images/production/phone.png')"
          style="width:38px;margin-right:8px;"
        />
        <b-navbar-brand
          tag="h1"
          class="mb-0 mr-1"
          style="   font-size: 1.4rem;"
        >
          02-222-2222
        </b-navbar-brand> -->
              <b-navbar-brand class="ml-1"> PLAYSLOT888 </b-navbar-brand>
              <b-img
                :src="require('@/assets/images/production/line.png')"
                style="width: 35px; cursor: pointer; height: 35px"
                class="d-none d-md-block d-xl-block"
              />
            </div>
          </b-link>
          <b-navbar-nav class="ml-auto">
            <div v-if="$store.state.auth.auth" class="d-flex align-items-end">
              <div class="mr-1">
                <b-button
                  variant="success"
                  class="d-none d-md-inline d-xl-inline"
                  size="md"
                  @click="onClickModalCredit"
                >
                  <font-awesome-icon
                    :icon="['fas', 'wallet']"
                    style="width: 18px"
                  />
                  &nbsp;<span class="align-middle">เติมเครดิต</span></b-button
                >
              </div>
              <div class="mr-1 d-flex align-self-center">
                <p class="text-white mb-0 text-right" nb>
                  <feather-icon
                    class="pointer"
                    icon="RefreshCwIcon"
                    @click="loadData()"
                  />
                  <b-spinner v-if="$store.state.auth.spinnerCredit" small />
                  {{ $store.state.auth.credit | toCurrency }} THB
                </p>
                <!-- <p class="text-success mb-0 text-right" style="font-size: 11px">
              0.00 THB
            </p> -->
              </div>
              <div v-if="$store.state.auth.auth" class="mr-1">
                <div class="d-flex align-items-end">
                  <div>
                    <b-nav-item-dropdown right>
                      <template #button-content>
                        <b-avatar />
                      </template>
                      <b-dropdown-item
                        @click="
                          () => {
                            $router.push('/profile');
                          }
                        "
                      >
                        โปรไฟล์
                      </b-dropdown-item>
                      <b-dropdown-item @click="onClickModalCredit">
                        เติมเงินเครดิต
                      </b-dropdown-item>
                      <b-dropdown-item @click="onClickModalWithdraw">
                        ถอนเงิน
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="
                          () => {
                            $router.push('/changepass');
                          }
                        "
                      >
                        เปลี่ยนรหัสผ่าน
                      </b-dropdown-item>
                      <b-dropdown-item href="#" @click="logout">
                        ออกจากระบบ
                      </b-dropdown-item>
                    </b-nav-item-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!$store.state.auth.auth" class="d-flex">
              <div class="mr-1">
                <b-button
                  variant="danger"
                  size="md"
                  @click="onClickOpenModalRegis('login')"
                  >เข้าสู่ระบบ</b-button
                >
              </div>
              <div>
                <b-button
                  variant="success"
                  size="md"
                  @click="onClickOpenModalRegis('register')"
                  >ลงทะเบียน</b-button
                >
              </div>
            </div>
            <!-- <div
          v-if="$store.state.auth.userData !== ''"
          style="border-left: 1px solid #FFFFFF;border-right: 1px solid #FFFFFF;"
        >
          <div class="d-flex align-items-end">
            <font-awesome-icon
              class="ml-1 "
              :icon="['fas', 'user']"
              style="width: 18px;color:#FFFFFF;margin-right:3px;"
            />
            <div class="mr-1">
              <b-nav-item-dropdown
                :text="$store.state.auth.userData"
                right
              >
                <b-dropdown-item href="#">
                  โปรไฟล์
                </b-dropdown-item>
                <b-dropdown-item
                  href="#"
                  @click="logout"
                >
                  ออกจากระบบ
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </div>
          </div>
        </div> -->
            <!-- <div class="ml-1">
          <b-nav-item
            href="#"
            @click="onClickOrder"
          >
            <font-awesome-icon
              class="mr-1"
              :icon="['fas', 'list-alt']"
              style="width: 20px;margin-right: 0px !important;"
            />
            ออเดอร์
          </b-nav-item>
        </div> -->
          </b-navbar-nav>
        </b-navbar>
        <!-- <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-nav class="ml-2"> -->

        <div class="container-fluid horizontal-scrollable">
          <div class="row text-center bg-dark">
            <div class="col-3 onHover">คาสิโนสด</div>
            <a href="/games/show#slot" class="col-3 onHover">สล็อต</a>
            <a href="/games/show#fishing" class="col-3 onHover">ยิงปลา</a>
            <div
              class="col-3 onHover"
              @click="onClickLoginGame('sport', 'sport', '')"
            >
              กีฬา
            </div>
            <div
              class="col-3 onHover"
              @click="onClickLoginGame('lotto', 'lotto', '')"
            >
              หวย
            </div>
            <div class="col-3 onHover">คีโน่</div>
            <div class="col-3 onHover">เทรด</div>
          </div>
        </div>

        <!-- <b-navbar type="dark" variant="dark" class="justify-content-center">
          <b-navbar-brand></b-navbar-brand>
          <b-navbar-toggle
            target="nav-collapse"
            style="font-size: 16px"
          ></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item
                class="onHover text-center item font-weight-bolder rounded"
                @click="onClickLoginGame('sport', 'sport', '')"
                >กีฬา</b-nav-item
              >
              <b-nav-item
                href="/games/show"
                class="onHover text-center item font-weight-bolder rounded"
                >สล็อต</b-nav-item
              >
              <b-nav-item
                href="/games/show#fishing"
                class="onHover text-center item font-weight-bolder rounded"
                >ยิงปลา</b-nav-item
              >
              <b-nav-item
                class="onHover item text-center font-weight-bolder rounded"
                @click="onClickLoginGame('lotto', 'lotto', '')"
                >ล็อตเตอรี่</b-nav-item
              >
              <b-nav-item
                class="onHover item text-center font-weight-bolder rounded"
                @click="onClickLoginGame('tfgaming', 'esport', '')"
                >E-SPORT</b-nav-item
              >
            </b-navbar-nav>
          </b-collapse> -->
        <!-- <div class="navigation">
          <b-button
            variant="dark"
            class="onHover font-weight-bolder"
            @click="onClickLoginGame('sport', 'sport', '')"
            >กีฬา</b-button
          >
          <b-link href="/game/slot">
            <b-button variant="dark" class="onHover font-weight-bolder"
              >สล็อต</b-button
            >
          </b-link>
          <b-link href="/game/fishing">
            <b-button variant="dark" class="onHover font-weight-bolder"
              >คาสิโนสด</b-button
            >
          </b-link>
          <b-button
            variant="dark"
            class="onHover font-weight-bolder"
            @click="onClickLoginGame('lotto', 'lotto', '')"
            >ล็อตเตอรี่</b-button
          >
          <b-button
            variant="dark"
            class="onHover font-weight-bolder"
            @click="onClickLoginGame('tfgaming', 'esport', '')"
            >E-SPORT</b-button
          >
        </div> -->
        <!-- </b-navbar> -->
      </div>
      <!-- </b-navbar-nav>
    </b-navbar> -->
      <router-view />
      <div class="row m-auto container-fluid">
        <div class="col-xl-2 col-md-2 col-6">
          <div class="mb-1">
            <p class="font-weight-bolder text-white">ข้อมูล</p>
          </div>
          <div class="mb-1">
            <a class="text-white" href="#">คำถามที่พบบ่อย</a>
          </div>
          <div class="mb-1"><a class="text-white" href="#">ปฏิทิน</a></div>
          <div class="mb-1">
            <a class="text-white" href="#">โปรแกรมพันธมิตร</a>
          </div>
          <div class="mb-1">
            <a class="text-white" href="#">ผลการแข่งขัน</a>
          </div>
        </div>
        <div class="col-xl-2 col-md-2 col-6">
          <div class="mb-1">
            <p class="font-weight-bolder text-white">เกี่ยวกับ</p>
          </div>
          <div class="mb-1">
            <a class="text-white" href="#">เกี่ยวกับเรา</a>
          </div>
          <div class="mb-1"><a class="text-white" href="#">ติดต่อเรา</a></div>
          <div class="mb-1">
            <a class="text-white" href="#">การเล่นเกมแบบต่าง ๆ</a>
          </div>
        </div>
        <div class="col-xl-2 col-md-2 col-6">
          <div class="mb-1">
            <p class="font-weight-bolder text-white">กฏระเบียบ</p>
          </div>
          <div class="mb-1">
            <a class="text-white" href="#">นโยบายความเป็นส่วนตัว</a>
          </div>
          <div class="mb-1">
            <a class="text-white" href="#">ข้อกำหนดและเงื่อนไขทั่วไป</a>
          </div>
          <div class="mb-1"><a class="text-white" href="#">AML นโยบาย</a></div>
          <div class="mb-1">
            <a class="text-white" href="#">กฏการเดิมพันกีฬา</a>
          </div>
        </div>
        <div class="col-xl-2 col-md-2 col-6">
          <div class="mb-1">
            <p class="font-weight-bolder text-white">อนุญาตให้ใช้สิทธิ์</p>
          </div>
          <div class="mb-1">
            <a class="text-white" href="#"
              ><b-img
                style="width: 50%"
                :src="require('@/assets/images/logo/gc-logo.png')"
            /></a>
          </div>
        </div>
        <div class="col-xl-2 col-md-2 col-6">
          <div class="mb-1">
            <p class="font-weight-bolder text-white">ตามเรามา</p>
          </div>
          <div class="mb-1">
            <b-img
              class="mr-1"
              :src="require('@/assets/images/production/facebook.png')"
              style="width: 30px; cursor: pointer"
            />
            <b-img
              class="mr-1"
              :src="require('@/assets/images/production/line.png')"
              style="width: 30px; cursor: pointer"
            />
            <b-img
              :src="
                require('@/assets/images/production/Instagram-icon-removeb.png')
              "
              style="width: 40px; cursor: pointer"
            />
          </div>
          <div class="mb-1">
            <b-img
              class="mr-1"
              :src="require('@/assets/images/logo/download1.svg')"
              style="width: 87px; cursor: pointer"
            />
            <b-img
              class="mr-1"
              :src="require('@/assets/images/logo/download2.svg')"
              style="width: 87px; cursor: pointer"
            />
          </div>
        </div>
        <div class="col-xl-2 col-md-2 col-6 d-flex justify-content-center">
          <b-img
            class="mr-1"
            :src="require('@/assets/images/logo/18.svg')"
            style="width: 60px; cursor: pointer"
          />
        </div>
      </div>
      <div class="row bg-dark m-0">
        <div class="container-fluid">
          <b-img
            class="w-100 d-xl-block d-sm-none d-md-none d-none"
            :src="require('@/assets/images/logo/footer.png')"
          />
          <b-img
            class="w-100 d-xl-none"
            :src="require('@/assets/images/logo/footer-mobile.png')"
          />
        </div>
      </div>
      <div class="container mt-1">
        <p class="text-white text-center">Presented By LnwFirstZa</p>
      </div>
      <Credit />
      <Withdraw />
    </b-overlay>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import {
  BNavbar,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BNavItem,
  BImg,
  BNavbarBrand,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BBadge,
  BAvatar,
  BSpinner,
  BOverlay,
  BCollapse,
  BNavbarToggle,
  BLink,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import Login from "@/views/production/auth/Login.vue";
import Credit from "@/views/production/components/deposit-modal.vue";
import Withdraw from "@/views/production/components/withdraw-modal.vue";
// import useJwt from "@/auth/jwt/useJwt";
// import { initialAbility } from "@/libs/acl/config";

export default {
  components: {
    BNavbar,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BNavItem,
    BImg,
    BNavbarBrand,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BAvatar,
    BSpinner,
    BOverlay,
    BLink,
    BCollapse,
    BNavbarToggle,
    FeatherIcon,
    BBadge,
    Login,
    Credit,
    Withdraw,
  },
  data() {
    return {
      Search: "",
    };
  },
  computed: {
    checkLogin() {
      return localStorage.getItem("auth_user");
    },
    sizepagee() {
      return this.$store.getters["app/currentBreakPoint"];
    },
    CountShop() {
      return this.$store.state.shop.totalItem;
    },
    Userlogin() {
      return this.$store.state.auth.userData;
    },
  },
  watch: {},
  created() {
    const token =
      localStorage.getItem("auth_token") !== null
        ? localStorage.getItem("auth_token")
        : null;
    const auth =
      localStorage.getItem("auth_user") !== null
        ? localStorage.getItem("auth_user")
        : null;
    const payload = {
      token,
      auth,
    };
    this.$store.commit("auth/SET_LOGIN_COMPLETE", payload);
    this.loadData();
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("auth_token");
      localStorage.removeItem("auth_user");
      localStorage.removeItem("userData");
      this.$store.commit("auth/SET_LOGOUT");

      // Reset ability
      // this.$ability.update(initialAbility);

      // Redirect to login page
      if (this.$router.currentRoute.path !== "/")
        this.$router.push({ path: "/" });
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          icon: "AlertCircleIcon",
          variant: "success",
          text: "คุณออกจากระบบสำเร็จ",
        },
      });
      this.$store.commit("auth/SET_CREDIT", 0);
    },
    onClickOpenModalRegis(val) {
      this.$store.commit("customer/SET_OPEN_REGISTER");
      this.$store.commit("customer/SET_TYPE_MODAL", val);
    },
    onClickModalCredit() {
      const item = { type: "deposit" };
      this.$store.commit("auth/SET_OPEN_MODAL_CREDIT", item);
    },
    onClickHonmepage() {
      if (this.$router.currentRoute.path !== "/")
        this.$router.push({ path: "/" }).catch(() => true);
    },
    onClickModalWithdraw() {
      const item = { type: "withdraw" };
      this.$store.commit("auth/SET_OPEN_MODAL_CREDIT", item);
    },
    onClickLoginGame(val, model, gameid) {
      const token = localStorage.getItem("auth_token");
      if (token !== "" && token !== undefined && this.$store.state.auth.auth) {
        this.$store.commit("game/SET_LOADING", true);
        const payload = {
          type: val,
          model,
          gameId: gameid,
        };
        this.$store
          .dispatch("game/loginGame", payload)
          .then((result) => {
            console.log(result);
            if (result.data.success) {
              this.$router.push({
                name: "login-game",
                params: {
                  url: result.data.items[0].url,
                },
              });
              // window.location.assign(result.data.items[0].url);
              this.$store.commit("game/SET_LOADING", false);
            } else {
              this.$store.commit("game/SET_LOADING", false);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: "ล็อกอินเกมส์ไม่สำเร็จ",
                },
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$store.commit("customer/SET_OPEN_REGISTER");
        this.$store.commit("customer/SET_TYPE_MODAL", "login");
      }
    },
    loadData() {
      const token =
        localStorage.getItem("auth_token") !== null
          ? localStorage.getItem("auth_token")
          : null;
      this.$store.commit("auth/SET_SPINNER_CREDIT", true);
      if (token !== null) {
        this.$store
          .dispatch("transaction/getCredit")
          .then((result) => {
            if (result.data.success) {
              this.$store.commit("auth/SET_CREDIT", result.data.credit);
              this.$store.commit("auth/SET_SPINNER_CREDIT", false);
            } else {
              this.$store.commit("auth/SET_CREDIT", 0);
              this.$store.commit("auth/SET_SPINNER_CREDIT", false);
            }
          })
          .catch((error) => {
            this.$store.commit("auth/SET_SPINNER_CREDIT", false);
            console.log(error);
          });
      } else {
        this.$store.commit("auth/SET_SPINNER_CREDIT", false);
      }
    },
  },
  setup() {
    const { contentWidth } = useAppConfig();
    return { contentWidth };
  },
};
</script>
<style>
.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute !important;
  z-index: 2;
}

.navbar-expand-md {
  z-index: 1;
}

.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}

.onHover:hover {
  background-color: #e73e3e !important;
  color: white !important;
  cursor: pointer;
}

.item {
  padding: 3px 10px 3px 10px;
  font-size: 16px;
}

.shadow-item {
  box-shadow: 1px -1px 5px -1px rgba(108, 108, 108, 0.6) !important;
}

.pointer {
  cursor: pointer;
}
@media (max-width: 420px) {
  #nav-top .navbar-brand {
    font-size: 1.4rem;
  }
}

/* The heart of the matter */

.horizontal-scrollable > .row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-scrollable > .row > .col-3 {
  display: inline-block;
}
/* Decorations */
.col-3 {
  color: #fff;
  font-size: 12px;
  padding-bottom: 8px;
  padding-top: 5px;
}
</style>
