<template>
  <div>
    <b-modal
      v-model="OpenModal"
      hide-footer
      hide-header-close
      centered
      header-bg-variant="dark"
      body-bg-variant="black"
      size="md"
    >
      <template #modal-title>
          <span class="text-white">{{ title }}</span>
      </template>
      <logInFrom ref="loginModal1" :type="type" @loginsuccess="onClickCloseModalRegis" />
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import logInFrom from '../components/login-from.vue'

export default {
  components: {
    BModal,
    logInFrom,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isOpen: false,
      OpenModal: false,
      type: this.$store.state.customer.typeModal,
      title: '',
    }
  },
  computed: {

  },
  watch: {
    '$store.state.customer.ModalRegister': {
      handler(val) {
        if (val === this.OpenModal) return
        this.OpenModal = val
      },
    },
    '$store.state.customer.typeModal': {
      handler(val) {
        if (val === 'login') {
        this.title = 'ลงชื่อเข้าใช้บัญชีของคุณ'
      } else if (val === 'register') {
        this.title = 'การลงทะเบียน'
      }
      },
    },
    OpenModal(val) {
      if (!val) {
        this.onClickCloseModalRegis()
      }
    },

  },
  methods: {
    onClickCloseModalRegis() {
      this.$store.commit('customer/SET_ClOSE_REGISTER')
    },
    onType(val){
      if (val === 'login') {
        this.title = 'ลงชื่อเข้าใช้บัญชีของคุณ'
      } else if (val === 'register') {
        this.title = 'การลงทะเบียน'
      }
      this.type = val
    },

  },
}
</script>

<style lang="scss" scoped>
.buttonFB {
   background-color:#3c5998 !important;
   color: white !important;
}
</style>

<style lang="scss">
.bg-header-modal {
  background-color: #161620 !important;
  color: white !important;
}

.modal-header {
  justify-content: center;
}
</style>
